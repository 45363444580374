h1,
h2,
h3,
h4,
h5,
h6, button {
  font-family: $expressive-font-family;
  font-weight: $expressive-font-weight;
  font-style: $base-font-style;
  line-height: $base-line-height;
  color: $font-brand-color;
}
body,
html, p, li, time, ul {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-style: $base-font-style;
  line-height: $base-line-height;
  color: $font-light-color;
  font-size: 1rem;
}


// $min_width: 320px;
// $max_width: 1200px;
// $min_font: 16px;
// $max_font: 24px;

// $mod_1: 1.1; // mobile
// $mod_2: 1.1; // desktop

// h1 {  
//   font-size: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
// }
// h2 {  
//   font-size: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;

//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
// }
// h3 { 
//   font-size: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
// }
// h4 { 
//   font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;

//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
// }
// h5 { 
//   font-size: $mod_1*$mod_1*$mod_1 *1rem;
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;

//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
// }
// h6 { 
//   font-size: $mod_1*$mod_1 *1rem;
//   line-height: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;
//   margin-top: $mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1*$mod_1 *1rem;

//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
// }
// p, li, time { 
//   font-size: $mod_1 *1rem;
//   @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
// }


a {
  color: $brand-color;
  text-decoration-skip: ink;
  transition: color $default-duration $default-transition;

  &:hover {
    color: shade($brand-color, 25%);
  }
}

span {
  color: $brand-color;
}

ul.no-bullets {
  list-style-type: circle;
  padding-left: 1rem;

  svg {
    width: 1rem;
    fill: $brand-color;
    margin: auto 0.5rem;   
    vertical-align: baseline;
  }
}