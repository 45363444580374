#section-home {
  text-align: center;
  h1 {
    font-size: 40px;
    line-height: 50px;
  }
  p {
    font-size: 30px;
  }
  u {
    text-decoration-color: $brand-color;
  }
}
