@import "0-libs/!dir";
@import "1-tools/!dir";
@import "2-settings/!dir";
@import "3-elements/!dir";
@import "4-objects/!dir";
@import "5-components/!dir";


@media (max-width: 768px) {


  section#about-me img {
    margin: 80px auto 20px;
  }

  section img {
    width: 50%;
    height: auto;
    margin: auto;
    display: flex;
  }
  section#values {
    img {
      max-height: 200px;
    }
  }
  .scroll-container {
    width: 100%;
    min-height: 100vh;

    section,
    footer {
      width: 100%;
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;
      background: $background-color;
      background: radial-gradient(
        circle,
        rgb(33, 33, 33) 0%,
        rgba($background-color, 1)
      );
    }
  }
}
span.expressive {
  font-size: 6rem;
  line-height: 0;
}

.scroll-container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  section,
  footer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    scroll-snap-align: start;
    background: $background-color;
    background: radial-gradient(
      circle,
      rgb(33, 33, 33) 0%,
      rgba($background-color, 1)
    );
  }
}

.btn-primary {
  background-color: $brand-color;
  border: $brand-color;

  line-height: 1.8rem;
  &:hover {
    background-color: (darken($brand-color, 10%));
  }
}

section#nothing {
  img {
    max-height: 400px;
    margin: auto;
    display: block;
  }
}

footer {
  form {
    max-width: 500px;
    margin: auto;
  }
  img {
    margin: auto;
    display: flex;
  }
  input#exampleInputEmail1 {
    background: $gray;
    border: 1px solid $gray;
  }
}
