// Colors
$white: #fafafae5;
$full-white: #ffffff;
$red: #f13f25;
$black: #111016;
//$blue: #007acc;
$blue: #0892D0;
$orange: #d8641dfd;
$green: #13c63e;
$blue-green: #067f6f;

$light-gray: #dcdcdc;
$gray: #BBBBBB;
$dark-gray: #444444;
//60a5ff
//defaults
$brand-color: $orange; 

//fonts 
$font-color:                        $white; 
$font-brand-color:                  $brand-color;
$font-light-color:                  $gray;
$font-action-color:                 $brand-color; 
$link-color:                        $blue;

//background
$background-color:                  $black;
$background-color-hover:            lighten($light-gray, 5%);
$background-dark-color:             $black;
$background-dark-color-hover:       $dark-gray;

$tab-background-color: $white;

$border-color: $light-gray;

$icon-color: $gray; 
$icon-secondary-color: $light-gray;

$input-background-color: $full-white;

//buttons
 
                            //normal                        reversed 
$btn-ghost: (
    default: (
        background-color:   (transparent,                   transparent), 
        font-color:         ($brand-color,                  $white),
        border-color:       ($brand-color,                  $white)
    ),
    hover: (
        background-color:   ($white,                        $white),
        font-color:         ($brand-color,                  $red),
        border-color:       ($brand-color,                  $white),
    ),
    pressed: (
        background-color:   ($white,                        $white),
        font-color:         ($brand-color,                  $red),
        border-color:       ($brand-color,                  $white),
    )
);

$btn-cta: (
    default: (
        background-color:   ($brand-color,                  $white), 
        font-color:         ($white,                        $brand-color),
        border-color:       (transparent,                   transparent)
    ),
    hover: (
        background-color:   (darken($brand-color, 10%),     darken($white, 15%)),
        font-color:         ($white,                        $brand-color),
        border-color:       (transparent,                   transparent)
    ),
    pressed: (
        background-color:   (darken($brand-color, 15%),     darken($white, 15%)),
        font-color:         ($white,                        $brand-color),
        border-color:       (transparent,                   transparent)
    )
);

$btn-flat: (
    default: (
        background-color:   (transparent,                   transparent),
        font-color:         ($link-color,                   $link-color),
        border-color:       (transparent,                   transparent)
    ),
    hover: (
        background-color:   (transparent,                   transparent),
        font-color:         ($link-color,                   $link-color),
        border-color:       (transparent,                   transparent)
    ),
    pressed: (
        background-color:   (transparent,                   transparent),
        font-color:         ($link-color,                   $link-color),
        border-color:       (transparent,                   transparent)
    )
);