$default-duration: .5s;
$default-transition: ease-in-out;

$base-font-family: 'Roboto', sans-serif;
$expressive-font-family: 'Roboto Serif', serif;
$base-font-size: 1.1em;
$base-font-weight: 300;
$expressive-font-weight: 800;
$base-font-style: normal;

$base-line-height: 1.5;
$base-spacing: 1.5em;
